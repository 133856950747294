<template>
  <div class="row mt-2">
    <ParticipantGrowthTemplate
      :loaded="loaded"
      :legend-title="legendTitle"
      :report-data="participantGrowthData"
      :show-esu-count="true"
      @startDate="updateStartDate($event)"
      @endDate="updateEndDate($event)"
    />
  </div>
</template>

<script>
import {ref, computed} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {nebraskaStateWideParticipationInRange} from "@/Helpers/ApiCalls/ReportAPICalls";
import {isEmpty} from "lodash/lang";
import ParticipantGrowthTemplate from "@/views/SuperUserViews/Reporting/ParticipantGrowth/ParticipantGrowthTemplate";

export default {
  name: "NebraskaParticipantGrowth",
  components: {ParticipantGrowthTemplate},
  setup() {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const startDate = ref(new Date());
    const endDate = ref(new Date());
    const loaded = ref(false);
    const participantGrowthData = ref();

    async function getParticipantData() {
      let formatted_start = startDate.value.toISOString().slice(0, 10);
      let formatted_end = endDate.value.toISOString().slice(0, 10);
      await axios.get(nebraskaStateWideParticipationInRange(), {
        headers: {
          "Authorization": "Bearer " + authToken.value
        },
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end
        }
      }).then((result) => {
        participantGrowthData.value = result.data;
      }).finally(() => {
        if (!isEmpty(participantGrowthData.value)){
          loaded.value = true;
        }
      })
    }

    function updateStartDate(event) {
      startDate.value = event;
      getParticipantData();
    }

    function updateEndDate(event) {
      endDate.value = event;
      getParticipantData();
    }

    const legendTitle = computed(() => {
      return "Participant Growth for Nebraska Users of Project Para";
    })

    return {
      startDate,
      endDate,
      participantGrowthData,
      loaded,
      legendTitle,
      updateStartDate,
      updateEndDate,
    }
  }
}
</script>

<style scoped>

</style>